<template>
  <div id="hello">

    <textarea ref="textarea" id="text" placeholder="content" v-model="text" class="textarea-field"></textarea><br>

    <input type="number" placeholder="code" v-model="vc" @keyup.enter="tryToUnClock">

    <br>
    <button @click="tryToUnClock">R</button>
    <button @click="tryToUnUpload">W</button>
    <button @click="copy">C</button>
    <button @click="paste">V</button>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'HelloWorld',
  data() {
    return {
      vc: "",
      locked: true,
      text: "",
    }
  },
  methods: {
    tryToUnClock() {

      const body = {
        "action": "read",
        "code": this.vc,
      }
      console.log(body)
      axios.post('https://api.cb.cppzeal.com/', body
      ).then(res => {
        this.text = res.data.results[0].content
        this.locked = false
        console.log(res.data)

      }).catch(e => {
        console.log(e)
        this.vc = ""
      })
    },
    tryToUnUpload() {
      const body = {
        "action": "write",
        "code": this.vc,
        "content": this.text
      }
      axios.post('https://api.cb.cppzeal.com/', body
      ).then(res => {
        console.log(res.data)
        if (res.data.success) {
          alert("完成");
        } else {
          this.vc = ""
        }
      }).catch(e => {
        console.log(e)
        this.vc = ""
      })
    },
    copy() {
      // 获取 textarea 元素
      // this.check()
      var textarea = this.$refs.textarea;
      textarea
      // // 将 textarea 的内容复制到剪贴板
      // textarea.select();
      // document.execCommand("copy");
      navigator.clipboard.writeText(this.text).then(() => {
        alert('Content copied to clipboard');

      },() => {
        alert('Failed to copy');
      });

    },
    check() {
      navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
        if (result.state == "granted" || result.state == "prompt") {
          alert("Write access granted!");
        }
      });
    },
    paste(){
      navigator.clipboard.readText().then((clipboardText) => {
        console.log("剪贴板内容:", clipboardText);
        this.text=clipboardText
      }).catch((error) => {
        console.error("读取剪贴板失败:", error);
        alert("读取剪贴板失败：" + error);
      });
    }

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

#hello {
  font-size: large;
}

#text {
  min-width: 80vw;
  min-height: 50vh;
}

input,
textarea {
  font-weight: bold;
}

input {
  padding: 2px 4px;
  font-size: 20px;

}

textarea {
  padding: 5px 10px;
  font-size: 18px;
}

button {
  font-size: 30px;
  margin: 5px;
  font-weight: bold;

}
</style>
